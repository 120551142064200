@media (max-width: 599px) {
  @media (orientation: portrait) {
    .t-4 {
      font-size: 2.2vw !important;

    }
    .t-3 {
      font-size: 2.4vw !important;

    }
    .t-2 {
      font-size: 2.6vw !important;

    }
    .t-1 {
      font-size: 2.8vw !important;

    }
    .t0 {
      font-size: 3vw !important;

    }
    .t1 {
      font-size: 1.6vh !important;

    }

    .t2 {
      font-size: 1.8vh !important;

    }
    .t3 {
      font-size: 2vh !important;

    }
    .t4 {
      font-size: 2.2vh !important;

    }
    .t5 {
      font-size: 2.4vh !important;

    }
    .t6 {
      font-size: 2.6vh !important;

    }
    .t7 {
      font-size: 2.8vh !important;

    }
    .t8 {
      font-size: 3vh !important;

    }
    .t9 {
      font-size: 3.2vh !important;

    }
    .t10 {
      font-size: 3.4vh !important;

    }
    .t11 {
      font-size: 3.6vh !important;

    }
    .t12 {
      font-size: 3.8vh !important;

    }
    .t13 {
      font-size: 4vh !important;

    }
    .t14 {
      font-size: 4.2vh !important;

    }
    .t15 {
      font-size: 4.4vh !important;

    }
    .t16 {
      font-size: 4vh !important;

    }
    .t17 {
      font-size: 7vh !important;

    }
    .t18 {
      font-size: 8vh !important;

    }
    .t19 {
      font-size: 9vh !important;

    }
    .t20 {
      font-size: 10vh !important;

    }
  }
  @media (orientation: landscape) {
    .t-4 {
      font-size: 2.2vw !important;

    }
    .t-3 {
      font-size: 2.4vw !important;

    }
    .t-2 {
      font-size: 2.6vw !important;

    }
    .t-1 {
      font-size: 2.8vw !important;

    }
    .t0 {
      font-size: 3vw !important;

    }
    .t1 {
      font-size: 3.2vw !important;

    }
    .t2 {
      font-size: 3.4vw !important;

    }
    .t3 {
      font-size: 3.6vw !important;

    }
    .t4 {
      font-size: 3.8vw !important;

    }
    .t5 {
      font-size: 4vw !important;

    }
    .t6 {
      font-size: 4.2vw !important;

    }
    .t7 {
      font-size: 4.4vw !important;

    }
    .t8 {
      font-size: 4.6vw !important;

    }
    .t9 {
      font-size: 4.8vw !important;

    }
    .t10 {
      font-size: 5vw !important;

    }
    .t11 {
      font-size: 5.2vw !important;

    }
    .t12 {
      font-size: 5.4vw !important;

    }
    .t13 {
      font-size: 5.6vw !important;

    }
    .t14 {
      font-size: 5.8vw !important;

    }
    .t15 {
      font-size: 6vw !important;

    }
    .t16 {
      font-size: 7vw !important;

    }
    .t17 {
      font-size: 8vw !important;

    }
    .t18 {
      font-size: 9vw !important;

    }
    .t19 {
      font-size: 10vw !important;

    }
    .t20 {
      font-size: 10vw !important;

    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  @media (orientation: portrait) {
    .t-4 {
      font-size: 0.6vh !important;

    }
    .t-3 {
      font-size: 0.8vh !important;

    }
    .t-2 {
      font-size: 1vh !important;

    }
    .t-1 {
      font-size: 2.8vw !important;

    }
    .t0 {
      font-size: 3vw !important;

    }
    .t1 {
      font-size: 3.2vw !important;

    }
    .t2 {
      font-size: 1.8vh !important;

    }
    .t3 {
      font-size: 2vh !important;

    }
    .t4 {
      font-size: 2.2vh !important;

    }
    .t5 {
      font-size: 2.4vh !important;

    }
    .t6 {
      font-size: 2.6vh !important;

    }
    .t7 {
      font-size: 2.8vh !important;

    }
    .t8 {
      font-size: 3vh !important;

    }
    .t9 {
      font-size: 3.2vh !important;

    }
    .t10 {
      font-size: 3.4vh !important;

    }
    .t11 {
      font-size: 3.6vh !important;

    }
    .t12 {
      font-size: 3.8vh !important;

    }
    .t13 {
      font-size: 4vh !important;

    }
    .t14 {
      font-size: 4.2vh !important;

    }
    .t15 {
      font-size: 4.3vh !important;

    }
    .t16 {
      font-size: 5vh !important;

    }
    .t17 {
      font-size: 6vh !important;

    }
    .t18 {
      font-size: 7vh !important;

    }
    .t19 {
      font-size: 8vh !important;

    }
    .t20 {
      font-size: 9vh !important;

    }
  }
  @media (orientation: landscape) {
    .t-4 {
      font-size: 1vw !important;

    }
    .t-3 {
      font-size: 1.2vw !important;

    }
    .t-2 {
      font-size: 1.4vw !important;

    }
    .t-1 {
      font-size: 1.6vw !important;

    }
    .t0 {
      font-size: 1.8vw !important;

    }
    .t1 {
      font-size: 2vw !important;

    }
    .t2 {
      font-size: 2.2vw !important;

    }
    .t3 {
      font-size: 2.4vw !important;

    }
    .t4 {
      font-size: 2.6vw !important;

    }
    .t5 {
      font-size: 2.8vw !important;

    }
    .t6 {
      font-size: 3vw !important;

    }
    .t7 {
      font-size: 3.2vw !important;

    }
    .t8 {
      font-size: 3.4vw !important;

    }
    .t9 {
      font-size: 3.6vw !important;

    }
    .t10 {
      font-size: 3.8vw !important;

    }
    .t11 {
      font-size: 4vw !important;

    }
    .t12 {
      font-size: 4.2vw !important;

    }
    .t13 {
      font-size: 4.4vw !important;

    }
    .t14 {
      font-size: 4.6vw !important;

    }
    .t15 {
      font-size: 4.8vw !important;

    }
    .t16 {
      font-size: 5vw !important;

    }
    .t17 {
      font-size: 6vw !important;

    }
    .t18 {
      font-size: 7vw !important;

    }
    .t19 {
      font-size: 8vw !important;

    }
    .t20 {
      font-size: 9vw !important;

    }
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  @media (orientation: portrait) {
    .t-4 {
      font-size: 0.2vh !important;

    }
    .t-3 {
      font-size: 0.4vh !important;

    }
    .t-2 {
      font-size: 0.6vh !important;

    }
    .t-1 {
      font-size: 0.8vh !important;

    }
    .t0 {
      font-size: 1vh !important;

    }
    .t1 {
      font-size: 1.2vh !important;

    }
    .t2 {
      font-size: 1.4vh !important;

    }
    .t3 {
      font-size: 1.6vh !important;

    }
    .t4 {
      font-size: 1.8vh !important;

    }
    .t5 {
      font-size: 2vh !important;

    }
    .t6 {
      font-size: 2.2vh !important;

    }
    .t7 {
      font-size: 2.4vh !important;

    }
    .t8 {
      font-size: 2.6vh !important;

    }
    .t9 {
      font-size: 2.8vh !important;

    }
    .t10 {
      font-size: 3vh !important;

    }
    .t11 {
      font-size: 3.2vh !important;

    }
    .t12 {
      font-size: 3.4vh !important;

    }
    .t13 {
      font-size: 3.8vh !important;

    }
    .t14 {
      font-size: 4vh !important;

    }
    .t15 {
      font-size: 4.2vh !important;

    }
    .t16 {
      font-size: 5vh !important;

    }
    .t17 {
      font-size: 6vh !important;

    }
    .t18 {
      font-size: 7vh !important;

    }
    .t19 {
      font-size: 8vh !important;

    }
    .t20 {
      font-size: 9vh !important;

    }
  }
  @media (orientation: landscape) {
    .t-4 {
      font-size: 0.6vw !important;

    }
    .t-3 {
      font-size: 0.8vw !important;

    }
    .t-2 {
      font-size: 1vw !important;

    }
    .t-1 {
      font-size: 1.2vw !important;

    }
    .t0 {
      font-size: 1.4vw !important;

    }
    .t1 {
      font-size: 1.6vw !important;

    }
    .t2 {
      font-size: 1.8vw !important;

    }
    .t3 {
      font-size: 2vw !important;

    }
    .t4 {
      font-size: 2.2vw !important;

    }
    .t5 {
      font-size: 2.4vw !important;

    }
    .t6 {
      font-size: 2.6vw !important;

    }
    .t7 {
      font-size: 2.8vw !important;

    }
    .t8 {
      font-size: 3vw !important;

    }
    .t9 {
      font-size: 3.2vw !important;

    }
    .t10 {
      font-size: 3.4vw !important;

    }
    .t11 {
      font-size: 3.6vw !important;

    }
    .t12 {
      font-size: 3.8vw !important;

    }
    .t13 {
      font-size: 4vw !important;

    }
    .t14 {
      font-size: 4.2vw !important;

    }
    .t15 {
      font-size: 4.4vw !important;

    }
    .t16 {
      font-size: 5vw !important;

    }
    .t17 {
      font-size: 6vw !important;

    }
    .t18 {
      font-size: 7vw !important;

    }
    .t19 {
      font-size: 8vw !important;

    }
    .t20 {
      font-size: 9vw !important;

    }
  }
}

@media (min-width: 1280px) {
  @media (orientation: portrait) {
    .t-4 {
      font-size: 0.4vw !important;

    }
    .t-3 {
      font-size: 0.6vw !important;

    }
    .t-2 {
      font-size: 0.8vw !important;

    }
    .t-1 {
      font-size: 1vw !important;

    }
    .t0 {
      font-size: 1.2vh !important;

    }
    .t1 {
      font-size: 1.4vh !important;

    }
    .t2 {
      font-size: 1.5vh !important;

    }
    .t3 {
      font-size: 1.6vh !important;

    }
    .t4 {
      font-size: 1.8vh !important;

    }
    .t5 {
      font-size: 2vh !important;

    }
    .t6 {
      font-size: 2.2vh !important;

    }
    .t7 {
      font-size: 2.4vh !important;

    }
    .t8 {
      font-size: 2.6vh !important;

    }
    .t9 {
      font-size: 2.8vh !important;

    }
    .t10 {
      font-size: 3vh !important;

    }
    .t11 {
      font-size: 3.2vh !important;

    }
    .t12 {
      font-size: 3.4vh !important;

    }
    .t13 {
      font-size: 3.6vh !important;

    }
    .t14 {
      font-size: 3.8vh !important;

    }
    .t15 {
      font-size: 4vh !important;

    }
    .t16 {
      font-size: 5vh !important;

    }
    .t17 {
      font-size: 6vh !important;

    }
    .t18 {
      font-size: 7vh !important;

    }
    .t19 {
      font-size: 8vh !important;

    }
    .t20 {
      font-size: 9vh !important;

    }
  }
  @media (orientation: landscape) {
    .t-4 {
      font-size: 0.1vw !important;

    }
    .t-3 {
      font-size: 0.3vw !important;

    }
    .t-2 {
      font-size: 0.4vw !important;

    }
    .t-1 {
      font-size: 0.6vw !important;

    }
    .t0 {
      font-size: 0.8vw !important;

    }
    .t1 {
      font-size: 1vw !important;

    }
    .t2 {
      font-size: 1.2vw !important;

    }
    .t3 {
      font-size: 1.4vw !important;

    }
    .t4 {
      font-size: 1.6vw !important;

    }
    .t5 {
      font-size: 1.8vw !important;

    }
    .t6 {
      font-size: 2vw !important;

    }
    .t7 {
      font-size: 2.2vw !important;

    }
    .t8 {
      font-size: 2.4vw !important;

    }
    .t9 {
      font-size: 2.6vw !important;

    }
    .t10 {
      font-size: 2.8vw !important;

    }
    .t11 {
      font-size: 3vw !important;

    }
    .t12 {
      font-size: 3.4vw !important;

    }
    .t13 {
      font-size: 3.6vw !important;

    }
    .t14 {
      font-size: 3.8vw !important;

    }
    .t15 {
      font-size: 4vw !important;

    }
    .t16 {
      font-size: 5vw !important;

    }
    .t17 {
      font-size: 6vw !important;

    }
    .t18 {
      font-size: 7vw !important;

    }
    .t19 {
      font-size: 8vw !important;

    }
    .t20 {
      font-size: 9vw !important;

    }
  }
}


@media screen and (min-width: 2900px){
  .t-4 {
    font-size: 0.1vw !important;

  }
  .t-3 {
    font-size: 0.1vw !important;

  }
  .t-2 {
    font-size: 0.1vw !important;

  }
  .t-1 {
    font-size: 0.2vw !important;

  }
  .t0 {
    font-size: 0.4vw !important;

  }
  .t1 {
    font-size: 0.8vw !important;

  }
  .t2 {
    font-size: 1.0vw !important;

  }
  .t3 {
    font-size: 1.2vw !important;

  }
  .t4 {
    font-size: 1.4vw !important;

  }
  .t5 {
    font-size: 1.6vw !important;

  }
  .t6 {
    font-size: 0.8vw !important;

  }
  .t7 {
    font-size: 2.0vw !important;

  }
  .t8 {
    font-size: 2.2vw !important;

  }
  .t9 {
    font-size: 2.4vw !important;

  }
  .t10 {
    font-size: 2.6vw !important;

  }
  .t11 {
    font-size: 2.8vw !important;

  }
  .t12 {
    font-size: 3.2vw !important;

  }
  .t13 {
    font-size: 3.4vw !important;

  }
  .t14 {
    font-size: 3.1vw !important;

  }
  .t15 {
    font-size: 3vw !important;

  }
  .t16 {
    font-size: 4vw !important;

  }
  .t17 {
    font-size: 5vw !important;

  }
  .t18 {
    font-size: 6vw !important;

  }
  .t19 {
    font-size: 7vw !important;

  }
  .t20 {
    font-size: 8vw !important;

  }
}


@media screen and (min-width: 3100px){
  .t-4 {
    font-size: 0.1vw !important;

  }
  .t-3 {
    font-size: 0.3vw !important;

  }
  .t-2 {
    font-size: 0.4vw !important;

  }
  .t-1 {
    font-size: 0.6vw !important;

  }
  .t0 {
    font-size: 0.4vw !important;

  }
  .t1 {
    font-size: 1vw !important;

  }
  .t2 {
    font-size: 1.2vw !important;

  }
  .t3 {
    font-size: 1.4vw !important;

  }
  .t4 {
    font-size: 1.6vw !important;

  }
  .t5 {
    font-size: 1.4vw !important;

  }
  .t6 {
    font-size: 2vw !important;

  }
  .t7 {
    font-size: 2.2vw !important;

  }
  .t8 {
    font-size: 2.4vw !important;

  }
  .t9 {
    font-size: 2.6vw !important;

  }
  .t10 {
    font-size: 2.8vw !important;

  }
  .t11 {
    font-size: 3vw !important;

  }
  .t12 {
    font-size: 3.4vw !important;

  }
  .t13 {
    font-size: 3.6vw !important;

  }
  .t14 {
    font-size: 3.8vw !important;

  }
  .t15 {
    font-size: 4vw !important;

  }
  .t16 {
    font-size: 5vw !important;

  }
  .t17 {
    font-size: 6vw !important;

  }
  .t18 {
    font-size: 7vw !important;

  }
  .t19 {
    font-size: 8vw !important;

  }
  .t20 {
    font-size: 9vw !important;

  }
}


@media screen and (min-width: 3700px){
  .t-4 {
    font-size: 0.1vw !important;

  }
  .t-3 {
    font-size: 0.3vw !important;

  }
  .t-2 {
    font-size: 0.4vw !important;

  }
  .t-1 {
    font-size: 0.3vw !important;

  }
  .t0 {
    font-size: 0.2vw !important;

  }
  .t1 {
    font-size: 0.3vw !important;

  }
  .t2 {
    font-size: 0.5vw !important;

  }
  .t3 {
    font-size: 0.3vw !important;

  }
  .t4 {
    font-size: 0.4vw !important;

  }
  .t5 {
    font-size: 0.5vw !important;

  }
  .t6 {
    font-size: 2vw !important;

  }
  .t7 {
    font-size: 2.2vw !important;

  }
  .t8 {
    font-size: 2.4vw !important;

  }
  .t9 {
    font-size: 2.6vw !important;

  }
  .t10 {
    font-size: 2.8vw !important;

  }
  .t11 {
    font-size: 3vw !important;

  }
  .t12 {
    font-size: 3.4vw !important;

  }
  .t13 {
    font-size: 3.6vw !important;

  }
  .t14 {
    font-size: 3.8vw !important;

  }
  .t15 {
    font-size: 4vw !important;

  }
  .t16 {
    font-size: 5vw !important;

  }
  .t17 {
    font-size: 6vw !important;

  }
  .t18 {
    font-size: 7vw !important;

  }
  .t19 {
    font-size: 8vw !important;

  }
  .t20 {
    font-size: 9vw !important;

  }
}
