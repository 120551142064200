@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins-Black"), url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
}
.PB {
  font-family: "Poppins-Black", sans-serif !important;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Black"), url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}
.PBold {
  font-family: "Poppins-Bold", sans-serif !important;
}
@font-face {
  font-family: "Poppins-Semi-Bold";
  src: local("Poppins-Semi-Bold"), url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
.PSBold {
  font-family: "Poppins-Semi-Bold", sans-serif !important;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
.PR {
  font-family: "Poppins-Regular", sans-serif !important;
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
.PM {
  font-family: "Poppins-Medium", sans-serif !important;
}


