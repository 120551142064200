.p-inputtext {
  width: 100%;
}
.p-galleria-thumbnail-item{
  aspect-ratio: 1 !important;
}

.pi{
  font-family: 'primeicons', serif;
}


.password .p-inputtext {
  border-radius: 0 6px 6px 0 !important;
  border-left: 0 !important;
}

.p-calendar .p-inputtext {
  text-align: center !important;
  font-size: inherit !important;
}

.p-avatar-group p-avatar {
  margin-left: -1rem !important;
}

.p-dropdown {
  width: 100%;
}

app-month-timeshift  .p-datepicker .p-datepicker-header {
  display: none !important;
}
.p-carousel-indicators{
  display: none !important;
}
.p-multiselect{
  background-color: var(--color10) !important;
  color: var(--black) !important;
  outline: none !important;
  border: none !important;
  border-radius: 2rem !important;
  height: 100%;
}
.p-autocomplete{
  background-color: var(--color10) !important;
  color: var(--black) !important;
  outline: none !important;
  border: none !important;
  border-radius: 2rem !important;
  box-shadow: none !important;
  height: 100%;
  width: 100%;
}
.p-inputtext{
  color: var(--black) !important;
  background: #fafafa !important;
}
.mat-mdc-dialog-container .mdc-dialog__content .p-calendar{
  width: 100% !important;
}
.mat-mdc-dialog-container .mdc-dialog__content .p-calendar .p-inputtext {
  width: 100% !important;
  font-size: inherit !important;
  text-align: left !important;

}
.p-calendar{
  background-color: var(--color10) !important;
  color: var(--black) !important;
  outline: none !important;
  border: none !important;
  border-radius: 2rem !important;
  height: 100%;
  width: 100%;
}
 .p-datepicker table th , .p-datepicker table td , .p-datepicker .p-timepicker  {
  padding: 0 !important;
}
.p-organizationchart .p-organizationchart-node-content {
  border: none !important;
  border-radius: 2rem !important;
  padding: 0.5rem !important;
}
.p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
  top: 0 !important;
}
.p-password{
  width: 100% !important;
}
