.w-auto {
  width: auto !important
}

.w-0 {
  width: 0 !important;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.w-30 {
  width: 30% !important;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20% !important;
}

.w-45 {
  width: 45% !important;
}

.w-47 {
  width: 47% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-97 {
  width: 97% !important;
}


.w-98 {
  width: 98% !important;
}


.w-99 {
  width: 99% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-1vw {
  width: 1rem !important;
}

.w-2vw {
  width: 2rem !important;
}

.w-3vw {
  width: 3vw !important;
}

.w-4vw {
  width: 4vw !important;
}

.w-5vw {
  width: 5vw !important;
}

.w-8vw {
  width: 8rem !important;
}

.w-7vw {
  width: 7rem !important;
}

.w-9vw {
  width: 9vw !important;
}

.w-10vw {
  width: 10vw !important;
}

.w-11vw {
  width: 11vw !important;
}

.w-13vw {
  width: 13vw !important;
}

.w-17vw {
  width: 17vw !important;
}

.w-19vw {
  width: 19vw !important;
}

.w-21vw {
  width: 19vw !important;
}


.w-20px {
  width: 20px !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-90 {
  width: 90% !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-s15vw {
  width: 15.5vw !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-30rem {
  width: 32rem !important;
}

.w-3vw {
  width: 3vw !important;
}


