.underline {
  text-decoration: none !important;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.cna {
  cursor: not-allowed;
}

.l1 {
  line-height: 1.5em !important;
}

.l2 {
  line-height: 2em !important;
}

.l0 {
  line-height: 0 !important;
}

.dots5 {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 7.5em;
  white-space: normal;
}

.dots2 {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 3em;
  white-space: normal;
}

.dots3 {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 4.5em;
  white-space: normal;
}

.word-br {
  word-break: break-all;
}

.cp {
  cursor: pointer;
}

.opacity1 {
  opacity: 1 !important;
}

.opacity07 {
  opacity: 0.7;
}

.opacity04 {
  opacity: 0.4 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap;
}

.room .txthover {
  display: none;
}

.room:hover .txthover {
  display: block !important;
}

.ovyauto {
  overflow-y: auto !important;
  max-height: 68vh !important;
  min-height: 68vh !important;
}

.ovyauto1 {
  overflow-y: auto !important;
  max-height: 65vh !important;
  min-height: 65vh !important;
}

.ovyauto2 {
  overflow-y: auto !important;
}

.ovyauto3 {
  overflow-y: scroll !important;
}

.date {
  color: var(--text2) !important;
  font-size: 0.6rem !important;
}

.tag {
  font-family: Poppins-Regular, serif;
  padding: 0.4rem 0.75rem;
  border-radius: 10rem;
}

.d-none {
  display: none;
}

.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-text {
  white-space: nowrap;
}
