.br50 {
  border-radius: 50% !important;
}
.br-half{
  border-radius: 0.5rem !important;
}



.br_gray{
  border: 1px solid #E9E9E9 !important;
  border-radius: 12rem !important;

}
.br_gray3{
  border: 1px solid #DBDBDB !important;
  border-radius: 12rem !important;

}
.br_gray2{
  border: 1px solid #A7A7A7 !important;

}
.br {
  border: 1px solid var(--color1) !important;

}
.br2 {
  border: 1px solid var(--color2) !important;
  border-radius: 12px;
}
.br3 {
 border: 1px solid #A7A7A7 !important;
  border-radius: 12px;
}
.shadow_br {
  border: 1px solid var(--color7) !important;
  box-shadow: 0 0 5px 0 var(--color7);
  border-radius: 12px;
}
.shadow_br2 {
  border: 1px solid var(--btn2) !important ;
  box-shadow: 0 0 5px 0 var(--btn2);
  border-radius: 12px;
}
.br_color2 {
  border: 1px solid var(--color2) !important;
}
.br_color7 {
  border: 1px solid var(--color7) !important;
}

.br1vw {
  border-radius: 1vw !important;
}

.brlr1vw {
  border-radius: 0vw 1vw 1vw 0 !important;
}

.brrl1vw {
  border-radius: 1vw 0vw 0vw 1vw !important;
}

.br20px {
  border-radius: 20px !important;
}

.br10px {
  border-radius: 10px !important;
}

.br5px {
  border-radius: 5px !important;
}

.br2vw {
  border-radius: 2vw !important;
}
