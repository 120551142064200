
.mat-button {
  border-radius: 0.5vw !important;
}

.mat-raised-button.mat-primary {
  background-color: var(--color3) !important;
  border-radius: 0.5vw !important;
}

.mat-calendar-body-selected {
  background-color: var(--color3) !important;
  color: white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color18) !important;
}

.mat-calendar-body, .mat-calendar-table-header th, .mat-calendar-body-label, .mat-calendar-period-button {
  font-size: inherit !important;
}

.mat-dialog-content {
  margin: 0 !important;
}
.mdc-icon-button {
  fill: currentColor;
  font-size:var(--mdc-icon-state-layer-size, 24px) !important ;
  width: var(--mdc-icon-state-layer-size, 24px) !important ;
  height:var(--mdc-icon-state-layer-size, 24px) !important ;
}
.icon_size{
font-size: 30px !important;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target{
  width: auto !important;
  height: auto !important;
}
.mdc-icon-button {
  width: var(--mdc-icon-button-state-layer-size, 30px) !important ;
  height:var(--mdc-icon-button-state-layer-size, 30px) !important ;
  padding: 12px !important;
}
.mini-icon{
  width: 2rem !important ;
  height:2rem !important ;
  padding: 0 !important;
}
.mini-icon .pi{
    font-size: 1rem !important;
    line-height: 1rem !important;
  }
.fill_white{
  fill: white !important;
}
 .mat-mdc-tab-label-container{
  display: none !important;
}
.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base{
  height: fit-content !important;
}
.mat-mdc-menu-item{
  min-height: auto !important;
  border-radius: 0 !important;
  font-family: Poppins-Regular, serif;
}
.mat-mdc-menu-content {
  padding: 0 !important;
}
.mat-mdc-menu-item .mat-icon {
  margin-right: 5px !important;
  width: 15px !important;
  height: 15px !important;
  font-size: 15px !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-width: 0 !important;
  border-bottom-style: solid;
}
.mat-mdc-table .mdc-data-table__row {
  height: 40px !important;
}
.mat-mdc-table .mdc-data-table__header-row{
  height: 40px !important;
  background-color: var(--color3) !important;
  border-radius: 10px !important;
}
.mat-mdc-table .mat-mdc-header-cell:first-child{
  border-radius: 5px 0 0 5px !important;
}
.mat-mdc-table .mat-mdc-header-cell:last-child{
  border-radius: 0 5px 5px  0 !important;
}

 .mat-mdc-snack-bar-container .mdc-snackbar__surface {
   background-color: var(--color1) !important;
 }
.mdc-snackbar__label {
text-align: center !important;
}
.mat-mdc-table tbody, .mat-mdc-table tfoot, .mat-mdc-table thead, .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row, .mat-mdc-table .mat-mdc-header-cell{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background{
  border-radius: 50% !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--color7) !important;
  background-color: var(--color7) !important;
}
.mat-mdc-radio-button .mdc-form-field{
  width: 100%;
}
.mdc-form-field>label{
  width: 100% !important;
}

.mat-mdc-paginator {
  background: transparent !important;
}
