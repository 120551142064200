@font-face {
  font-family: "Cairo-Black";
  src: local("Cairo-Black"), url("../fonts/Cairo/Cairo-Black.ttf") format("truetype");
}
.CB {
  font-family: "Cairo-Black", sans-serif !important;
}
@font-face {
  font-family: "Cairo-Bold";
  src: local("Cairo-Bold"), url("../fonts/Cairo/Cairo-Bold.ttf") format("truetype");
}
.CBold {
  font-family: "Cairo-Bold", sans-serif !important;
}
@font-face {
  font-family: "Cairo-Semi-Bold";
  src: local("Cairo-Semi-Bold"), url("../fonts/Cairo/Cairo-SemiBold.ttf") format("truetype");
}
.CSBold {
  font-family: "Cairo-Semi-Bold", sans-serif !important;
}
@font-face {
  font-family: "Cairo-Regular";
  src: local("Cairo-Regular"), url("../fonts/Cairo/Cairo-Regular.ttf") format("truetype");
}
.CR {
  font-family: "Cairo-Regular", sans-serif !important;
}
@font-face {
  font-family: "Cairo-Medium";
  src: local("Cairo-Medium"), url("../fonts/Cairo/Cairo-Medium.ttf") format("truetype");
}
.CM {
  font-family: "Cairo-Medium", sans-serif !important;
}

