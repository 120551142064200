.home-pad {
  padding: 3vw 10rem !important;
}

.home-pad2 {
  padding: 3vw 20rem !important;
}

.home-pad3 {
  padding: 3vw 15rem !important;
}

.home-pad4 {
  padding: 3vw 10rem !important;
}

.pdg0 {
  padding: 0 !important;
}

.pdg0-5 {
  padding: 0.5rem !important;
}

.pdg1vw {
  padding: 1rem !important;
}

.pdg2vw {
  padding: 2rem !important;
}

.pdg5rem {
  padding: 5rem !important;
}

.pdg05vw {
  padding: 0.5rem !important;
}

.plr-1vw {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.plr-0-5vw {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pr-0-5vw {
  padding-right: 0.5rem !important;
}

.pl-0-5vw {
  padding-left: 0.5rem !important;
}


.pr-1vw {
  padding-right: 1rem !important;
}

.pr-2vw {
  padding-right: 2rem !important;
}

.pdg025vw {
  padding: 0.25rem !important;
}

.plr-2vw {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.plr-3vw {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.plr-5vw {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.pr-5vw {
  padding-right: 5rem !important;
}

.pr-4vw {
  padding-right: 4rem !important;
}

.pr-3vw {
  padding-right: 3rem !important;
}

.pl-3vw {
  padding-left: 3rem !important;
}

.pl-2vw {
  padding-left: 2rem !important;
}

.pl-4vw {
  padding-left: 4rem !important;
}

/* .pr-4vh {
  padding-right: 7vw !important;
} */
.plr-10vw {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.plr-15vw {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.plr-20vw {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.ptb-05vw {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.ptb-025vw {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.ptb-1vw {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.ptb-1-5vw {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.ptb-2vw {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.ptb-3vw {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.ptb-5vw {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.ptb-10vw {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0vw {
  padding-top: 0 !important;
}

.pt-3vw {
  padding-top: 3rem;
}

.pt-1vw {
  padding-top: 1rem;
}

.pt-2vw {
  padding-top: 2rem;
}

.pt-4vw {
  padding-top: 4rem;
}

.pt-5vw {
  padding-top: 5rem;
}

.pb-3vw {
  padding-bottom: 3rem;
}

.pb-2vw {
  padding-bottom: 2vw;
}

.pt-s05vw {
  padding-top: 0.5vw;
}

.pt-10vw {
  padding-top: 10rem;
}

.pt-5vw {
  padding-top: 5rem;
}

.pb-5vw {
  padding-bottom: 5rem !important;
}

.pb-10vw {
  padding-bottom: 10rem !important;
}

.pb-1vw {
  padding-bottom: 1rem;
}

.plr-20vw {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.pr-20vw {
  padding-right: 20rem !important;
}

.pb {
  padding-bottom: 3rem;
}

.pl-1rem {
  padding-left: 1rem !important;
}

.pl-5vw {
  padding-left: 5rem !important;
}

.pl-6vw {
  padding-left: 6rem !important;
}
