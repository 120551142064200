.pr {
  position: relative;
}

.pa {
  position: absolute !important ;
}

.pf {
  position: fixed;
}

.ps {
  position: sticky;
}
.z0 {
  z-index:0;
}
.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z1000{
  z-index: 1000;
}
.z2000{
  z-index: 2000;
}

.flex-center{
  display: flex; justify-content: center; align-items: center;
}

