.cna-color {
  color: rgba(128, 128, 128, 0.5) !important;
}

.text2 {
  color: var(--text2) !important;
}

.text3 {
  color: var(--text3) !important;
}

.text4 {
  color: var(--text4) !important;
}

.color1 {
  color: var(--color1) !important;
}

.color2 {
  color: var(--color2) !important;
}

.color3 {
  color: var(--color3) !important;
}

.color4 {
  color: var(--color4) !important;
}

.color5 {
  color: var(--color5) !important;
}

.color6 {
  color: var(--color6) !important;
}

.color7 {
  color: var(--color7) !important;
}

.color8 {
  color: var(--color8) !important;
}

.color9 {
  color: var(--btn2) !important;
}
.color10 {
  color: var(--color9) !important;
}

.color12 {
  color: var(--color12) !important;
}

.color14 {
  color: var(--color14) !important;
}

.color-white {
  color: var(--white) !important
}

.color-black {
  color: var(--black) !important
}

.color-gray {
  color: grey !important
}

.color-red {
  color: var(--red) !important
}

.bgcolor-trans {
  background-color: transparent !important;
}

.bgcolor-default {
  background-color: var(--defaultbgc) !important
}

.bgcolor-white {
  background-color: var(--white) !important
}

.bgcolor-red {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.bgcolor1 {
  background-color: var(--color1) !important;
  color: var(--white);
}

.bgcolor103 {
  background-color: var(--color103) !important;
}

.bgcolordisabled {
  background-color: var(--text3) !important;
  color: var(--white) !important;

}

.bgcolor2 {
  background-color: var(--color2) !important;
  color: var(--white) !important;

}

.bgcolor3 {
  background-color: var(--color3) !important;
}

.bgcolor4 {
  background-color: var(--color4) !important;
}

.bgcolor5 {
  background-color: var(--color5) !important;
  color: var(--white);

}

.bgcolor6 {
  background-color: var(--color6) !important;
  color: var(--white) !important;
}

.bgcolor180 {
  background-color: #E8E8FF !important;
  color: var(--black) !important;

}

.bgcolor7 {
  background-color: var(--color7) !important;
  color: var(--white) !important;
}

.bgcolor8 {
  background-color: var(--btn2) !important;
}

.bgcolor9 {
  background-color: var(--color9) !important;
}

.bgcolor10 {
  background-color: var(--color10) !important;
}

.bgcolor0 {
  background-color: transparent !important;
}

.bgcolor11 {
  background-color: var(--color8) !important;
}

.bgcolor12 {
  background-color: var(--color12) !important;
}

.bgcolor13 {
  background-color: var(--color13) !important;
}

.bgcolor14 {
  background-color: var(--text3) !important;
}

.bgcolor15 {
  background-color: var(--color15) !important;
}

.bgcolor18 {
  background-color: var(--color18) !important;
}
