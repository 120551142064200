
@use '@angular/material' as mat;
*{
  margin: 0;
  padding: 0;
  overflow: hidden;
}


.custom-toast {
  &.toast-success {
    background-color: var(--color1); // Custom success color
  }

  &.toast-error {
    background-color: var(--color2); // Custom error color
  }

  // You can add more custom styles here
  .toast-title {
    font-weight: bold;
  }

  .toast-message {
    color: white;
  }
}

// Override default toast container styles if needed
.toast-container {
  .ngx-toastr {
    &:hover {
      box-shadow: 0 0 12px rgba(0,0,0,0.3);
    }
  }
}

:root {
  --btn1: #7777d2;
  --btn2: #f3904f;
  --text1: #b3b2d1;
  --text2: #a8a8ad;
  --text3: #A7A7A7;
  --text4: #969798;
  --color1: #000080;
  --color2: #EB4C10;
  --color3: #E8E8FF;
  --color4: #FBC940;
  --color5: #F3904F;
  --color6: #7777D2;
  --color7: #3CCF4E;
  --color103: #fbf6f6;
  --color2-alpha: rgba(241, 89, 50, 0.2);
  --color8: #535390;
  --color9: #44A790;
  --color10: #fafafa;
  --color12: #E8F2F9;
  --color18: #31317a80;
  --color13: #c7c7c7;
  --color14: #062546;
  --color15: #EFF1FA;
  --white: white;
  --black: black;
  --red: red;
}

@import url(./assets/styles/material.scss);
@import url(./assets/styles/borders.scss);
@import url(./assets/styles/font.scss);
@import url(./assets/styles/fontfaces.scss);
@import url(./assets/styles/fontfaces_ar.scss);
@import url(./assets/styles/colors.scss);
@import url(./assets/styles/heights.scss);
@import url(./assets/styles/margins.scss);
@import url(./assets/styles/paddings.scss);
@import url(./assets/styles/positions.scss);
@import url(./assets/styles/text.scss);
@import url(./assets/styles/widths.scss);
@import url(./assets/styles/primeNg.scss);
@import "../node_modules/ngx-spinner/animations/ball-spin";
@import 'ngx-toastr/toastr';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


@mixin flex-container($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-align($justify, $align) {
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-direction($direction) {
  display: flex;
  flex-direction: $direction;
}

.fxRow {
  @include flex-direction(row);
  flex-wrap: wrap;
}

.fxRow-nw {
  @include flex-direction(row);
}


.fxColumn {
  @include flex-direction(column);
}

.fxRowReverse {
  @include flex-direction(row-reverse);
}

.fxColumnReverse {
  @include flex-direction(column-reverse);
}

.fxAlignStartCenter {
  @include flex-align(start, center);
}

.fxAlignCenterCenter {
  @include flex-align(center, center);
}

.fxAlignEndCenter {
  @include flex-align(end, center);
}

.fxAlignBetweenCenter {
  @include flex-align(space-between, center);
}

.fxAlignAroundCenter {
  @include flex-align(space-around, center);
}

.fxAlignStartStart {
  @include flex-align(start, start);
}

.fxAlignCenterStart {
  @include flex-align(center, start);
}

.fxAlignEndStart {
  @include flex-align(end, start);
}

.fxAlignBetweenStart {
  @include flex-align(space-between, start);
}

.fxAlignAroundStart {
  @include flex-align(space-around, start);
}

.fxAlignStartEnd {
  @include flex-align(start, end);
}

.fxAlignCenterEnd {
  @include flex-align(center, end);
}

.fxAlignEndEnd {
  @include flex-align(end, end);
}

.fxAlignBetweenEnd {
  @include flex-align(space-between, end);
}

.fxAlignAroundEnd {
  @include flex-align(space-around, end);
}

@mixin column-gap($value) {
  column-gap: $value;
}

@mixin row-gap($value) {
  row-gap: $value;
}

@mixin gap($value) {
  gap: $value;
}

.fxGap0-5 {
  @include gap(0.5rem);
}

.fxGap1 {
  @include gap(1rem);
}

.fxColGap1 {
  @include column-gap(1rem);
}

.fxRowGap1 {
  @include row-gap(1rem);
}

.fxColGap2 {
  @include column-gap(2rem);
}

.fxRowGap2 {
  @include row-gap(2rem);
}

.fxGap2 {
  @include gap(2rem);
}

.fxGap3 {
  @include gap(3rem);
}

.fxGap4 {
  @include gap(4rem);
}

.fxGap5 {
  @include gap(5rem);
}


@mixin flex-max-width($value) {
  flex: 0 0 $value;
  max-width: $value;
}

.fxFlex30 {
  @include flex-max-width(30%);
}

.fxFlex22 {
  @include flex-max-width(22%);
}

.fxFlex45 {
  @include flex-max-width(45%);
}

.fxFlex50 {
  @include flex-max-width(45%);
}

.fxFlex60 {
  @include flex-max-width(60%);
}

.fxFlex40 {
  @include flex-max-width(40%);
}

.fxFlex30 {
  @include flex-max-width(60%);
}

.fxFlex35 {
  @include flex-max-width(35%);
}

.fxFlex30 {
  @include flex-max-width(30%);
}

.btn{
  border: none;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
}

.paginator {
  display: flex;
  justify-content: center;
  margin-top:2vw;
  //margin-top: 10vw;
}
//button:disabled,
//button[disabled]{
//  border: 1px solid #999999;
//  background-color: #cccccc;
//  color: #666666;
//}

.btn-danger {
  background-color: #dc3545; /* Red background color */
  color: white; /* White text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding for the button */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.btn-danger:hover {
  background-color: #c82333; /* Darker red on hover */
}

.btn-danger:active {
  background-color: #bd2130; /* Even darker red when the button is pressed */
}

.btn-danger:disabled {
  background-color: #e74c3c; /* Slightly lighter red for disabled state */
  cursor: not-allowed; /* Disable cursor */
  opacity: 0.65; /* Slightly transparent */
}

.text-danger{
  color:#dc3545;
}
