.h-auto {
  height: auto !important
}

.h-0 {
  height: 0 !important;
}

.h-15 {
  height: 15% !important;
}

.h-30 {
  height: 30% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-3vw {
  height: 3vw !important;
}

.h-2vw {
  height: 2rem !important;
}

.h-1vw {
  height: 1rem !important;
}

.h-s105vw {
  height: 1.5vw !important;
}

.h-4vw {
  height: 4vw !important;
}

.h-5vw {
  height: 5vw !important;
}

.h-8vw {
  height: 8vw !important;
}

.h-20vw {
  height: 20vw !important;
}

.h-13vw {
  height: 13vw;
}

.h-15vw {
  height: 15vw !important;
}

.h-10vw {
  height: 10vw !important;
}

.h-6vw {
  height: 6vw !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-20px {
  height: 20px !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 10.5rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-43rem {
  height: 43rem !important;
}

.h-svw {
  height: 0.2vw !important;
}

.h-40vh {
  height: 40vh;
}

.h-50vh {
  height: 50vh;
}

.h-60vh {
  height: 60vh;
}

.h-100vh {
  height: 100vh;
}

.max-h-70vh {
  max-height: 70vh;
}

.min-h-70vh {
  min-height: 70vh;

}

.h-100vh {
  height: 100vh;
}

.h-1-5vw {
  height: 1.5vw !important;
}
.h-2vw {
  height: 2vw !important;
}
