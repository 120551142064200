.m-2vw {
  margin: 2vw;
}

.m-1vw {
  margin: 1vw;
}

.mt-15 {
  margin-top: 15%;
}

.mt-10vw {
  margin-top: 10vw;
}

.mt-14vw {
  margin-top: 14vw;
}

.mt-16vw {
  margin-top: 16vw;
}

.mt-4vw {
  margin-top: 4vw;
}

.mt-3vw {
  margin-top: 3vw;
}

.mt-6vw {
  margin-top: 6vw;
}

.mt-s05vw {
  margin-top: 0.5vw !important;
}

.mt-1vw {
  margin-top: 1vw !important;
}

.mt-2vw {
  margin-top: 2vw !important;
}

.mt-5vw {
  margin-top: 5vw;
}

.mt-s2vw {
  margin-top: 2.5vw;
}

.mb-2vw {
  margin-bottom: 2rem;
}

.mb-3vw {
  margin-bottom: 3rem;
}

.mb-1vw {
  margin-bottom: 1rem;
}

.mr-1vw {
  margin-right: 1vw;
}

.mr-2vw {
  margin-right: 2vw;
}

.mr-3vw {
  margin-right: 3vw;
}

.mr-4vw {
  margin-right: 4vw;
}

.mr-5vw {
  margin-right: 5vw;
}

.mr-10vw {
  margin-right: 10vw;
}

.mr-30vw {
  margin-right: 30vw;
}

.mr-20vw {
  margin-right: 20vw;
}

.mr-s2104vw {
  margin-right: 21.5vw;
}

.ml-15vw {
  margin-left: 15vw;
}

.mr-15vw {
  margin-right: 15vw;
}

.ml-2vw {
  margin-left: 2vw;
}

.ml-3vw {
  margin-left: 3vw;
}

.ml-4vw {
  margin-left: 4vw;
}

.ml-5vw {
  margin-left: 5vw;
}

.ml-1vw {
  margin-left: 1vw;
}

.ml-70 {
  margin-left: 70% !important;
}

.ml-9vw {
  margin-left: 9vw;
}

.m-0 {
  margin: 0 !important;
}

.m-init {
  margin: initial !important;
}

.m-auto {
  margin: auto !important;
}

.mrl-0-3vw {
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

.mrl-0-5vw {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mrl-1vw {
  margin-right: 1rem;
  margin-left: 1rem;
}
